<template>
  <div class="row">
    <div class="col-12">

      <!--  payout summary    -->
      <!--      <div class="card">-->
      <!--        <div class="card-body">-->
      <!--          <div class="text-center">-->
      <!--            <div class="row">-->
      <!--              <div class="col-md-6 col-xl-4">-->
      <!--                <div class="py-1">-->
      <!--                  &lt;!&ndash;                <i class="fa fa-users fa-2x text-info"></i>&ndash;&gt;-->
      <!--                  <h3 class="text-info"> 2000 </h3>-->
      <!--                  <p class="text-uppercase mb-1 font-13 font-weight-medium">-->
      <!--                    Drivers-->
      <!--                  </p>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div class="col-md-6 col-xl-4">-->
      <!--                <div class="py-1">-->
      <!--                  &lt;!&ndash;                <i class="fa fa-user-shield fa-2x text-warning"></i>&ndash;&gt;-->
      <!--                  <h3 class="text-warning"> € 2000 </h3>-->
      <!--                  <p class="text-uppercase mb-1 font-13 font-weight-medium">-->
      <!--                    Verkoop-->
      <!--                  </p>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div class="col-md-6 col-xl-4">-->
      <!--                <div class="py-1">-->
      <!--                  <h3 class="text-success"> € 200 </h3>-->
      <!--                  <p class="text-uppercase mb-1 font-13 font-weight-medium">-->
      <!--                    Inkoop-->
      <!--                  </p>-->
      <!--                </div>-->
      <!--              </div>-->

      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <b-card v-if="showInitialArrearsForm">
        <h5 class="text-danger">* please set all the arrears of each driver incurred before the system was launched. Set it to the week before the system took over</h5>
      </b-card>
      <b-card v-else>
        <h5 class="text-danger">* System in session (this page will be removed soon)</h5>
      </b-card>
      <!--      end of payout summary -->

      <div class="card">
        <div class="card-body">
          <Sidepanel @onDriverSelected="onDriverSelected" />
          <div class="inbox-rightbar">
            <!--              <Toolbar />-->
            <!--           /////////////////////// CONTENT HERE -->
            <template v-if="driver">
              <div class="mt-0">
                <h5 class="font-18">
                  <span class="mr-3">{{driver['name']}} {{driver['other_names']}}</span>
                  <template v-if="driver['prospective_driver'] && driver['prospective_driver']['service_type']">
                    <span class="badge badge-dark p-1"> {{driver['prospective_driver']['service_type'] | serviceType}}</span>
                  </template>
                  <template v-else>
                    (No service selected)
                  </template>

                  <span class="ml-2">|</span>
                  <span class="ml-3">Accumulated Arrears = {{accumulatedArrears | toCurrencyFormat}}</span>

                </h5>
              </div>
              <hr>
              <b-card v-if="showInitialArrearsForm">
                <div class="row">
                  <div class="col-md-6">
                    <form action="#" @submit.prevent="setInitialArrears">
                      <div class="form-group">
                        <label>Previous arrears before system takes effect.</label>
                        <input type="number" step="0.01" class="form-control" v-model="initialArrears">
                        <small class="text-danger">(please do this before you make first payout with the system)</small>
                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn btn-success">Submit</button>
                      </div>
                    </form>
                  </div>

                </div>
              </b-card>
              <b-card v-else>
                System in session (this page will be removed soon)
              </b-card>
<!--              <hr>-->
<!--              <b-card v-if="paymentPlanForm.amountToPay > 0.00 && lastSystemArrearsUpdateDate == null">-->
<!--                <h4 class="card-title">Set payment plan</h4>-->
<!--                <form action="#" @submit.prevent="creatPaymentPlan">-->
<!--                  <div class="row">-->
<!--                    <div class="col-md-6">-->
<!--                      <div class="form-group">-->
<!--                        <label>Select week</label>-->
<!--                        <input type="week" :disabled="showInitialArrearsForm" :min="getPreviousWeekInHtmlFormat()" :max="getCurrentWeekInHtmlFormat()" step="0.01" class="form-control" v-model="paymentPlanForm.week" required>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="col-md-6">-->
<!--                      <div class="form-group">-->
<!--                        <label>Amount</label>-->
<!--                        <input type="number" disabled step="0.01" class="form-control" v-model="paymentPlanForm.amountToPay" required>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="col-md-6">-->
<!--                      <div class="form-group">-->
<!--                        <label>Spread over subsequent weeks</label>-->
<!--                        <input type="number" class="form-control" v-model="paymentPlanForm.spread" required>-->
<!--                        <small class="text-danger">(specify the number on subsequent weeks to spread over)</small>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="col-md-12">-->
<!--                      <div class="form-group">-->
<!--                        <button type="submit" class="btn btn-success">Submit</button>-->
<!--                      </div>-->
<!--                    </div>-->

<!--                  </div>-->
<!--                </form>-->
<!--              </b-card>-->

            </template>
            <!--           /////////////////////// END OF CONTENT HERE -->

          </div >

        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->


    </div>
  </div>
</template>

<script>
import {payoutService} from "../../../../apis/payout.service";
import Sidepanel from "./sidepanel";
import {Skeleton} from "vue-loading-skeleton";
import {financialAdministrationService} from "../../../../apis/financial.administration.service";
import moment from "moment";

export default {

  components: {
    Sidepanel,
    Skeleton
  },

  data(){
    return  {
      driver: null,
      selectedWeek: '',
      data: null,
      selectedIncomeCostType: '',
      initialArrears: '0.00',
      accumulatedArrears: '0.00',
      showInitialArrearsForm: false,
      lastSystemArrearsUpdateDate: null,
      paymentPlansForSelectedWeek: [],
      paymentPlanForm: {
        week: '',
        spread: '',
        amountToPay: 0.00
      }
    }
  },
  methods: {

    onDriverSelected(driver){
      this.driver = driver;
    },

    onFilterChanged(){
      console.log('filter changed called')
      this.fetchFinancialData();
    },

    getCurrentWeekInHtmlFormat(){
      const year = moment().year();
      const week = moment().week();
      return  year + '-W' + week.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      // console.log('weekInHtmlFormat',weekInHtmlFormat)
    },

    getPreviousWeekInHtmlFormat(){
      const year = moment().year();
      const week = moment().week();
      const lasWeek = week - 1;
      return  year + '-W' + lasWeek.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      // console.log('weekInHtmlFormat',weekInHtmlFormat)
    },

    creatPaymentPlan(){
      if(!this.driver){
        return;
      }
      //'week' => 'required',
      //   'driverUserId' => 'required',
      //       'amountToPay' => 'required',
      //       'spread' => 'required',
      const payload = {
        'driverUserId': this.driver['id'],
        'amountToPay': this.paymentPlanForm.amountToPay,
        'spread': this.paymentPlanForm.spread,
        'week': this.paymentPlanForm.week
      }



    },

    setInitialArrears(){

      if(!this.driver){
        return;
      }

      const payload = {
        'driverUserId': this.driver['id'],
        'accumulatedAmount': this.initialArrears
      }

      this.$store.dispatch('showLoader')
      payoutService.setInitialArrears(payload).then((data) => {
        this.$store.dispatch('hideLoader')

        if(!data.status){
          this.$store.dispatch('error', {message:data.message, showSwal: true})
          return;
        }

        this.$store.dispatch('success', {message:data.message, showSwal: true})
        this.accumulatedArrears = data['extra']['accumulated_amount']
        this.paymentPlanForm.amountToPay = data['extra']['accumulated_amount'];
        this.paymentPlanForm.week = this.getPreviousWeekInHtmlFormat()

      })

    },

    getArrearsForDriver(){
      if(!this.driver){
        return;
      }

      this.$store.dispatch('showLoader')
      payoutService.fetchArrears(this.driver['id']).then((data) => {
        this.$store.dispatch('hideLoader')

        if(!data.status){
          this.$store.dispatch('error', {message:data.message, showSwal: true})
          return;
        }

        this.accumulatedArrears = data['extra']['accumulated_amount'];
        this.showInitialArrearsForm = data['extra']['last_system_update_date'] == null;
        this.lastSystemArrearsUpdateDate = data['extra']['last_system_update_date'];
        this.initialArrears = data['extra']['accumulated_amount'];

        if(this.showInitialArrearsForm){
          // meaning the accumulated arrears = the currentArrears
          this.paymentPlanForm.amountToPay = data['extra']['accumulated_amount'];
          this.paymentPlanForm.week = this.getPreviousWeekInHtmlFormat();
        }else{
          this.paymentPlanForm.week = this.getCurrentWeekInHtmlFormat();

        }

      })

    },

    getPaymentPlanInWeeks(){

    }

  },
  watch: {
    driver: function (driver) {
      if(driver){
        this.getArrearsForDriver();
      }
    }
  },
  created() {
    this.$store.dispatch('setPageTitle','Set previous arrears');
    this.selectedWeek = this.getCurrentWeekInHtmlFormat();
    // this.fetchPayoutStanding()
  }
}
</script>

<style scoped>

</style>